import React from 'react'
import PageHelmet from '../components/pageHelmet'
import axios from 'axios'

// Components
import SubpageText from '../components/subpage/subpageText'

// Styles
import 'styles/global.scss'

type AboutQueryType = {
  cmsData: {
    data: {
      attributes: {
        Title: string
        Description: string
        Image: {
          Text: string
          Image: {
            data: {
              attributes: {
                url: string
              }
            }
          }
        }
      }
    }
  }
}

type AboutPageType = {
  serverData: AboutQueryType
}

const AboutPage: React.FC<AboutPageType> = ({ serverData }) => {
  const { cmsData } = serverData

  return (
    <PageHelmet title="Sailor's Food - About">
      <SubpageText page="about" title={cmsData.data?.attributes.Title} description={cmsData.data?.attributes.Description} descriptionImage={cmsData.data?.attributes.Image.Image.data ? `${process.env.GATSBY_PUBLIC_STRAPI_URL}${cmsData.data?.attributes.Image.Image.data?.attributes.url}` : undefined} imageText={cmsData.data?.attributes.Image.Text} />
    </PageHelmet>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function getServerData(context) {
  const data = await axios.get(`${process.env.GATSBY_STRAPI_URL}/api/about-subpage?populate=deep`).catch(() => null)

  return {
    status: 200,
    props: {
      cmsData: data ? data.data : null,
    },
    headers: {},
  }
}

export default AboutPage
